<div class="pt-3" id="tablecontent">
  <form [formGroup]="multiFormGroup">
    <p-table #pTable [(selection)]="selectedItem" formArrayName="multiFormArray" class="reusable-table"
      [columns]="columns" [value]="duplicateTableData" [paginator]="tableData?.length > 0 ? allowPagination : false"
      [rows]="rowsPerPage" [paginatorPosition]="'both'" [paginatorStyleClass]="'cstm-pagination'" [filterDelay]="200"
      [loadingIcon]="'spinner-grow'" [sortMode]="'multiple'" [(first)]="initialPage" (onSort)="sortData($event)"
      (onFilter)="onTableFilter($event)" dataKey="id" [globalFilterFields]="filterFields">
      <ng-template pTemplate="header">
        <tr>
          <ng-container *ngFor="let col of columns">
            <th *ngIf="col?.sort" [pSortableColumn]="col.name" class="table-column text-{{ col.align }} text-nowrap"
              [ngClass]="{ 'no-border-left': col === columns[0], 'no-border-right': col === columns[columns.length - 1]}">
              {{ col.name | cxTranslate }}
              <ng-container [ngSwitch]="col.type">
                <p-sortIcon [field]="col.name"></p-sortIcon>
              </ng-container>
            </th>
            <th *ngIf="col?.filter" class="table-column text-{{ col.align }} text-nowrap"
              [ngClass]="{ 'no-border-left': col === columns[0], 'no-border-right': col === columns[columns.length - 1]}">
              {{ col.name | cxTranslate }}
              <span style="cursor: pointer"><i class="fa fa-filter" (click)="op.toggle($event)"></i></span>
              <p-overlayPanel #op>
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-multiSelect *ngIf="getControl(col.name) as control" #multiselect [formControl]="control" [options]="col.filterOptions"
                  placeholder="{{ ('selectFilter'|cxTranslate) + ' '+ col.label}}"
                  filterPlaceHolder="{{ ('filterBy'|cxTranslate) + ' '+ col.label}}" [optionLabel]="col.name"
                  [optionValue]="col.name" [maxSelectedLabels]="1"
                  (onChange)="(filterLegalEntity && col.name === 'legalEntityCount') || apiFilterCall ? applyCustomfilter($event) : pTable.filter($event.value, col.name, 'in')">
                </p-multiSelect>
              </ng-template>
            </p-overlayPanel>
            </th>
            <th *ngIf="!col?.filter && !col?.sort" class="table-column text-{{ col.align }} text-nowrap"
              [ngClass]="{ 'no-border-left': col === columns[0], 'no-border-right': col === columns[columns.length - 1]}">
              {{ col.name | cxTranslate }}
            </th>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
        <tr>
          <td *ngFor="let col of columns" class="table-column text-{{ col.align }}" [formGroupName]="rowIndex"
            [ngClass]="{
              'no-border-left': col === columns[0],
              'no-border-right': col === columns[columns.length - 1],
              'no-border-bottom': rowIndex === tableData.length - 1,
              'highlight-row': (rowData['isQuotation'] || rowData['isObsolete'] || rowData['selected']) && allowQuotation,
              'pending-request': rowData['pending'] && rowData['pendingColName'].includes(col.name),
              'eta-highlight': rowData['etaFlag'] && rowData['etaColumn'].includes(col.name),
              'text-center': (col.name === 'refurbished' || col.name === 'duplicate' || col.name === 'delete' || col.name === 'select'),
            }">
            <ng-container [ngSwitch]="col.type">
              <ng-container *ngSwitchCase="columnType.text">
                <span [ngClass]="{'line-through': rowData['strike'], 'word-break-all' : col.name === 'companyPo'}">
                  {{ rowData[col.name] || "-" }}
                </span>
              </ng-container>
              <ng-container *ngSwitchCase="columnType.eta">
                <div class="text-nowrap">
                  <span [ngClass]="{'line-through': rowData['Etastrike'], 'word-break-all' : col.name === 'companyPo'}">
                    {{ formatDate(rowData[col.name]) || "-" }}
                  </span>
                  <br>
                  <span *ngIf="col?.orgEta"
                    [ngClass]="{'line-through': rowData['orginalEtastrike'], 'word-break-all' : col.name === 'companyPo'}">{{
                    " " +
                    rowData['orderDetailEta']?formatDate(rowData['orderDetailEta']):''}}</span>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="columnType.comment">
                <span>
                  {{ rowData[col.name] || " " }}
                </span>
              </ng-container>
              <ng-container *ngSwitchCase="columnType.number">
                <!-- TO add - For number -->
                <span [ngClass]="rowData['strike']? 'line-through' : ''"> {{ rowData[col.name] }} </span>
              </ng-container>
              <ng-container *ngSwitchCase="columnType.date">
                <div class="text-nowrap">
                  <span [ngClass]="rowData['strike']? 'line-through' : ''">
                    {{ formatDate(rowData[col.name]) || "-"}}
                  </span>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="columnType.price">
                <div class="text-right text-nowrap">
                  <span [ngClass]="rowData['strike']? 'line-through' : ''"> {{ rowData[col.name] | currencyPrice }} {{
                    rowData['currency'] | currencyFormat}}
                  </span>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="columnType.totalprice">
                <div class="text-right text-nowrap">
                  <span [ngClass]="rowData['strike']? 'line-through' : ''"> {{ rowData[col.name] | currencyPrice }} {{
                    rowData['totalcurrency'] | currencyFormat }} </span>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="columnType.link">
                <a *ngSwitchCase="columnType.link" class="cstm-anchor-primary"
                  (click)="navigateTo(rowData['navigateUrl'])">{{rowData[col.name]}}</a>
              </ng-container>
              <ng-container *ngSwitchCase="columnType.link2">
                <a *ngSwitchCase="columnType.link2" class="cstm-anchor-primary"
                  (click)="navigateTo(rowData['url'])">{{rowData[col.name]}}</a>
              </ng-container>

              <!-- Order History - link -->
              <ng-container *ngSwitchCase="columnType.navigate">
                <a *ngSwitchCase="columnType.navigate" (click)="navigateTo(rowData['navigateUrlId'])"
                  class="cstm-anchor-primary">{{
                  rowData[col.name] }}</a>
              </ng-container>
              <!-- Image -->
              <ng-container *ngSwitchCase="columnType.image">
                <a routerLink="{{rowData['navigateUrl']}}">
                  <img [src]="rowData[col.name]" class="productImage"
                    alt="../../../../assets/images_and_icons/image1.jpg" />
                </a>
              </ng-container>
              <ng-container *ngSwitchCase="columnType.image2">
                <a>
                  <img [src]="rowData[col.name]" class="productImage"
                    alt="../../../../assets/images_and_icons/image1.jpg" />
                </a>
              </ng-container>
              <!-- Info -->
              <ng-container *ngSwitchCase="columnType.info">
                <button class="btn order-request-review p-0 min-width-unset" (click)="handleReviewClick(rowData)">
                  <i class="fa fa-info order-request-icon" aria-hidden="true"></i>
                  <label class="lblReview">{{'review' | cxTranslate }}</label>
                </button>
              </ng-container>
              <!-- Button -->
              <ng-container *ngSwitchCase="columnType.button">
                <button (click)="col.action(rowData)">{{'click' | cxTranslate }}</button>
              </ng-container>
              <!-- Delete -->
              <ng-container *ngSwitchCase="columnType.delete">
                <button *ngIf="!disable" class="btn px-0 min-width-unset" (click)="handleDeleteClick(rowIndex)"
                  data-toggle="modal" data-target="#exampleModal" data-backdrop="false"
                  [disabled]="rowData['deleteoption']">
                  <i class="fa fa-trash fa-lg" aria-hidden="true"></i>
                </button>
              </ng-container>
              <!-- remove --> <!-- view -->
              <ng-container *ngSwitchCase="columnType.removeAndReview">
                <button class="btn pl-0 min-width-unset remove-review-btn" (click)="handleReviewClickAccess(rowData,'review')">
                  <i class="fa fa-info" aria-hidden="true"></i>
                  <br> <label class="lblReview"> {{ 'review' | cxTranslate }}</label>
                </button>
                <button class="btn min-width-unset remove-review-btn" (click)="handleReviewClickAccess(rowData,'reject')">
                  <i class="fa fa-remove fa-lg" aria-hidden="true"></i>
                  <br> <label class="lblReview"> {{ 'reject' | cxTranslate }}</label>
                </button>
              </ng-container>

              <!-- Duplicate -->
              <ng-container *ngSwitchCase="columnType.duplicate">
                <button class="btn px-0 min-width-unset" (click)="handleDuplicateClick(rowIndex)">
                  <i class="fa fa-copy fa-lg" aria-hidden="true"></i>
                </button>
              </ng-container>
              <!-- Quantity -->
              <ng-container *ngSwitchCase="columnType.quantity">
                <div class="quantity-selector d-flex justify-content-center">
                  <div class="d-flex" [ngClass]="{'btn-qty-highlight':rowData['qtyUpdatedFlag']}">
                    <button class="btn-qty" *ngIf="!disable" (click)="
                        decrementQuantity(rowIndex, col.name, rowData['step'], rowData['minQuantity'])"
                      [disabled]="checkMinimum(rowIndex, col.name, rowData['minQuantity'])">
                      <i class="fa fa-minus"></i>
                    </button>
                    <span *ngIf="disable">{{ rowData['quantity'] }} </span>
                    <input *ngIf="!disable" class="inputQuantity" [formControlName]="col.name" type="number"
                      [value]="rowData['quantity']" [step]="rowData['step'] ? rowData['step'] : 1"
                      [min]="rowData['minQuantity'] ? rowData['minQuantity'] : 1"
                      (input)="checkQty(value.value, rowData['step'], rowIndex, rowData['minQuantity'], rowData['maxQuantity'])"
                      (keydown)="onKeyDown($event, value.value, col.name, rowIndex, rowData['step'], rowData['minQuantity'])"
                      (blur)="updateQty(value.value, col.name, rowIndex, rowData['step'], rowData['minQuantity'])"
                      [title]="rowIndex === index ? title : ''" #value
                      [max]="rowData['maxQuantity'] ? rowData['maxQuantity'] : 1000" />
                    <button *ngIf="!disable" class="btn-qty"
                      (click)="incrementQuantity(rowIndex, col.name, rowData['step'], rowData['maxQuantity'])"
                      [disabled]="checkMaximum(rowIndex, col.name, rowData['maxQuantity'])">
                      <i class="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
              </ng-container>

              <!-- Radio Button -->
              <ng-container *ngSwitchCase="columnType.radio">
                <p-tableRadioButton [disabled]="rowData['selected']" [value]="rowData" [name]="rowData"
                  (click)="handleRadioClick(rowIndex)" />
              </ng-container>
              <!-- Check Box -->
              <ng-container *ngSwitchCase="columnType.checkbox">
                <p-checkbox [value]="rowIndex" [formControlName]="col.name" [disabled]="true"
                  (onChange)="handleCheckClick(rowIndex, col.name)" binary="true" variant="filled" />
              </ng-container>
              <ng-container *ngSwitchCase="columnType.checkbox2">
                <span *ngIf="rowData?.refurbished">
                  <p-checkbox *ngIf="rowData?.refurbished" [disabled]="true"
                    [ngClass]="{'disableChecBox':rowData?.refurbished}" [value]="rowIndex" [formControlName]="col.name"
                    (onChange)="handleCheckClick(rowIndex, col.name)"></p-checkbox>
                </span>
                <span *ngIf="!rowData?.refurbished">
                  <p-checkbox [value]="rowIndex" [formControlName]="col.name" checked=""
                    (onChange)="handleCheckClick(rowIndex, col.name)" binary="true" variant="filled" />
                </span>
              </ng-container>
              <!-- Check -->
              <ng-container *ngSwitchCase="columnType.tick">
                <div>
                  <span *ngIf="rowData[col.name]['status'] === 'icon'">
                    <i class="fa fa-check"></i>
                  </span>
                  <span *ngIf="rowData[col.name]['status'] === 'text'">
                    {{ 'pending' | cxTranslate }}
                  </span>
                  <span *ngIf="!rowData[col.name] && isEdit">
                    <p-checkbox [value]="rowIndex" [formControlName]="col.name"
                      (onChange)="handleCheckClick(rowIndex, col.name)" binary="true" variant="filled" />
                  </span>
                </div>
              </ng-container>
              <!-- for user profile -->
              <ng-container *ngSwitchCase="columnType.tick2">
                <div>
                  <span *ngIf="rowData[col.name] && !isEdit && !(rowData['pendingColName'].includes(col.name)) ">
                    <i class="fa fa-check"></i>
                  </span>
                  <span *ngIf="(rowData[col.name] || !rowData[col.name])  && isEdit">
                    <p-checkbox [value]="rowIndex" [formControlName]="col.name"
                      (onChange)="handleCheckClick(rowIndex, col.name)" binary="true" variant="filled" />
                  </span>
                  <span *ngIf="(rowData['pending'] && rowData['pendingColName'].includes(col.name))">
                    <p-checkbox [value]="rowIndex" [formControlName]="col.name"
                      (onChange)="handleCheckClick(rowIndex, col.name)" binary="true" variant="filled" />
                  </span>
                </div>
              </ng-container>
            </ng-container>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr *ngIf="!(loading$ | async) else noMessage">
          <td [attr.colspan]="columns?.length" class="no-border">
            {{ message | cxTranslate }}
          </td>
        </tr>
        <ng-template #noMessage>
          <tr class="mb-3">
            {{''}}
          </tr>
        </ng-template>
      </ng-template>
    </p-table>
  </form>
</div>