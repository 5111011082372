import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Column, ColumnType } from '../../models/tableConfiguration';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { GlobalService } from '../../services/global.service';
import { BehaviorSubject, Observable, Subject, debounceTime, takeUntil } from 'rxjs';
import { StorageService } from '../../services/storage.service';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-table-template',
  templateUrl: './table-template.component.html',
  styleUrl: './table-template.component.scss',
})
export class TableTemplateComponent implements OnInit, OnChanges, OnDestroy {

  @Input() isVisible: boolean = false;
  @Input() columns: Column[];
  @Input() disable: boolean;
  @Input() tableData: any[];
  @Input() allowPagination = false;
  @Input() rowsPerPage: number = 25;
  @Input() isEdit = false;
  @Input() message = 'noDataAvailable';
  @Input() allowQuotation = false;
  @Input() apiFilterCall = false;
  @Input() isRadioButton = false;
  @Input() filterLegalEntity = false;

  @Output() radioBtnClickEvent = new EventEmitter<any>();
  @Output() deleteBtnClickEvent = new EventEmitter<any>();
  @Output() reviewBtnClickEvent = new EventEmitter<any>();
  @Output() checkBtnClickEvent = new EventEmitter<any>();
  @Output() duplicateBtnClickEvent = new EventEmitter<any>();
  @Output() qtyUpdateClickEvent = new EventEmitter<any>();
  @Output() reviewRejectBtnClickEvent = new EventEmitter<any>();
  @Output() navigateClickEvent = new EventEmitter<any>();
  @Output() selectCartChange = new EventEmitter<any>();
  @Output() filteredData = new EventEmitter<any>();
  @Output() customFilter = new EventEmitter<any>();
  @Output() sortClickEvent = new EventEmitter<any>();

  @ViewChild('value') value: ElementRef;
  @ViewChild('pTable') pTable: any;

  public columnType = ColumnType;
  public multiFormGroup: FormGroup;
  public filterFormGroup: FormGroup;
  public duplicateTableData: any[];
  public previousRow = null;
  public selectedRow: number;
  public selectedIndex: number;
  public sortApplied: boolean = false;
  public checked: boolean = true;
  public filterColumn: string;
  public allowFilter: boolean = false;
  public initialPage: number = 0;
  public selectedItem: any;
  private unsubscribe$ = new Subject<void>();
  public title: string;
  public index: string;
  public fieldsCtrls: any;
  public filterFields: string[] = [];
  public radioIndex = -1;
  public currentOverlayPanel: OverlayPanel | null = null;
  public selectedQtyIndex;
  public loading$: Observable<any>;
  public currentOverlay: OverlayPanel;

  // Qty Inc / Dec 
  private qtyChangeSubject = new BehaviorSubject<any>(null);
  qtyChange$ = this.qtyChangeSubject.asObservable();

  constructor(
    private readonly fb: FormBuilder,
    private globalService: GlobalService,
    @Optional() private injector: Injector,
    public translateService: TranslateService,
    private storageService: StorageService,
    private datePipe: DatePipe
  ) {
    this.multiFormGroup = this.fb.group({
      multiFormArray: this.fb.array([]),
    });
  }

  ngOnInit() {
    this.selectedIndex = this.tableData?.at(0);
    this.handleSubscriptions();
  }

  handleSubscriptions() {
    this.globalService.defaultLegalEntitySubject$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        if (value) {
          this.initialPage = 0;
        }
      });
    this.loading$ = this.globalService.loading$;
    // Qty Inc Dec
    this.qtyChange$
      .pipe(debounceTime(1000))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data) {
          let formGroupObj = this.multiFormArray.at(this.selectedQtyIndex) as FormGroup;
          let formControlObj = formGroupObj.get(data?.formControlName);
          formControlObj.setValue(data?.updatedQty);
          this.qtyUpdateClickEvent.emit({
            index: this.selectedQtyIndex,
            formArray: this.multiFormArray.value,
          })
        }
      });
  }

  toggleOverlay(event: Event, overlayPanel: OverlayPanel) {
    if (this.currentOverlayPanel && this.currentOverlayPanel !== overlayPanel) {
      this.currentOverlayPanel.hide();
    }
    overlayPanel.toggle(event);
    this.currentOverlayPanel = overlayPanel;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tableData']) {
      this.radioIndex = -1;
      this.duplicateTableData = changes['tableData'].currentValue;
      this.multiFormArray.clear();
      this.createForm();
    }
    if (changes['columns']?.currentValue?.length > 0) {
      this.createFilterForm();
    }
  }

  public createForm() {
    if (this.tableData) {
      this.sortData();
      let c = 0;
      this.tableData?.forEach((row) => {
        const fieldsCtrls = {};
        this.columns?.forEach((ele) => {
          fieldsCtrls[ele.name] = new FormControl(
            this.tableData?.[c][ele.name],
            ele?.validations
          );
        });
        c += 1;
        this.multiFormArray?.push(this.fb.group(fieldsCtrls));
      });
    }
  }

  createFilterForm() {
    const fieldsCtrls = {};
    this.filterFields = [];
    this.columns?.forEach((ele) => {
      if (ele?.filter) {
        this.filterFields?.push(ele?.name);
        if (this.apiFilterCall) {
          fieldsCtrls[ele.name] = new FormControl(
            this.getPreSelectedValues(ele?.filterOptions, ele?.name)
          );
        } else {
          fieldsCtrls[ele.name] = new FormControl();
        }
      }
    });
    this.filterFormGroup = this.fb.group(fieldsCtrls);
  }

  isFormControl(control: AbstractControl | null): control is FormControl {
    return control instanceof FormControl;
  }

  getControl(colName: string): FormControl | null {
    const control = this.filterFormGroup?.get(colName);
    return this.isFormControl(control) ? control : null;
  }

  get multiFormArray(): FormArray {
    return this.multiFormGroup?.get('multiFormArray') as FormArray;
  }

  sortData(event = null) {
    this.duplicateTableData?.forEach((row, index) => {
      if (this.isRadioButton && row['selected']) {
        this.radioIndex = index;
      }
    })
    if (this.radioIndex != -1) {
      this.selectedItem = this.duplicateTableData[this.radioIndex];
    }
    if (event) {
      const fieldName = event.multisortmeta?.at(0)?.field;
      const order = event.multisortmeta?.at(0)?.order;
      this.sortClickEvent.emit({field : fieldName, order : order > 0 ? 'ASC' : 'DESC'})
    }
  }

  checkMinimum(rowIndex, columnName, minQty = 1): boolean {
    return this.multiFormArray?.value?.at(rowIndex)[columnName] <= minQty;
  }


  checkMaximum(rowIndex, columnName, maxQty = 1000): boolean {
    return this.multiFormArray?.value?.at(rowIndex)[columnName] >= maxQty;
  }

  formatDate(date) {
    if (date && date.length > 0) {
      const dateFormat = this.storageService.dateFormat;
      const parsedDate = Date.parse(date);
      if (isNaN(parsedDate)) {
        return '';
      }
      const formattedDate = new Date(parsedDate).toLocaleDateString('en-US');
      if (!!dateFormat) {
        return this.changeDateFormat(formattedDate, dateFormat.replace(/"/g, ''));
      } else {
        return formattedDate;
      }
    } else {
      return '';
    }
  }

  changeDateFormat(formatDate, dateFormat) {
    try {
      return this.datePipe.transform(formatDate, dateFormat);
    } catch (error) {
      console.error('Date format error:', error);
      return formatDate;
    }
  }

  findSymbol(currency) {
    let currencies = ['EUR', 'DKK', 'USD', 'GBP', 'CAD'];
    let symbols = ['€', 'DKK', '$', 'GBP', 'CAD'];
    return symbols.at(currencies.indexOf(currency));
  }

  getFilterOptions(options, columnName) {
    let filterOptions = [];
    options?.forEach((option) => filterOptions.push(option[columnName]));
    return filterOptions;
  }

  checkQty(qty, step, index, minQuantity = 1, maxQuantity = 1000): string {
    let updatedQty;
    this.index = index;
    if (qty <= minQuantity) {
      updatedQty = minQuantity;
      this.title = 'Value must be greater than or equal to ' + minQuantity;
    } else if (qty > maxQuantity) {
      updatedQty = maxQuantity;
      this.title = 'Value must be less than or equal to ' + maxQuantity;
    } else if (qty <= step) {
      updatedQty = step;
      this.title = 'Value must be greater than or equal to ' + step;
    } else if (qty % step != 0) {
      const minStep = Math.floor(qty / step) * step;
      const maxStep = Math.ceil(qty / step) * step;
      updatedQty = Math.round(qty / step) * step;
      this.title =
        'Please enter a valid value. The two nearest valid values are ' +
        minStep +
        ' and ' +
        maxStep +
        '.';
    } else {
      updatedQty = qty;
      this.title = '';
    }
    return updatedQty;
  }

  getPreSelectedValues(options, columnName) {
    return options
      .filter((option) => option.selected)
      .map((option) => option[columnName]);
  }

  updateQty(qty, colName, index, step, minimumQty) {
    let formGroupObj = this.multiFormArray.at(index) as FormGroup;
    let formControlObj = formGroupObj.get(colName);
    if (formControlObj.value !== this.tableData[index].quantity) {
      formControlObj.setValue(this.checkQty(qty, step, index, minimumQty));
      this.qtyUpdateClickEvent.emit({
        index: index,
        formArray: this.multiFormArray.value,
      });
      this.title = '';
    }
  }

  onKeyDown(event: KeyboardEvent, qty, colName, index, step, minimumQty) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.updateQty(qty, colName, index, step, minimumQty);
    }
  }

  toggleFilter(colName) {
    if (this.filterColumn === colName) {
      this.allowFilter = !this.allowFilter;
    } else {
      this.allowFilter = true;
    }
    this.filterColumn = colName;
  }

  applyCustomfilter(data) {
    if (this.apiFilterCall) {
      this.customFilter.emit(this.filterFormGroup.value);
    } else if (this.filterLegalEntity) {
      this.pTable.filter(data?.value, 'legalEntityNames', 'contains')
    }
  }

  findLegalEntityUid(legalEntities, name) {
    return legalEntities?.find(item => item.name === name).uid;
  }

  handleRadioClick(rowIndex) {
    const tableData = this.tableData;
    // Selecting New Row instead Active Row
    if (!tableData[rowIndex]?.selected) {
      this.selectCartChange.emit({
        cart: tableData.at(rowIndex),
        index: rowIndex,
      });
      this.initialPage = 0;
    }
  }

  handleCheckClick(rowIndex, colName) {
    this.checkBtnClickEvent.emit({
      index: rowIndex,
      formArray: this.multiFormArray.value,
      colName: colName,
    });
  }

  handleDeleteClick(rowIndex) {
    this.deleteBtnClickEvent.emit(rowIndex);
  }

  handleReviewClick(rowData) {
    this.reviewBtnClickEvent.emit(rowData);
  }

  handleReviewClickAccess(rowData, key) {
    this.reviewRejectBtnClickEvent.emit({ data: key, rowData: rowData });
  }

  handleDuplicateClick(rowIndex) {
    this.duplicateBtnClickEvent.emit(rowIndex);
  }

  decrementQuantity(rowIndex: number, formControlName: string, step: number = 1, minQty) {
    let formGroupObj = this.multiFormArray.at(rowIndex) as FormGroup;
    this.selectedQtyIndex = rowIndex;
    let formControlObj = formGroupObj.get(formControlName);
    let currentQty = formControlObj.value - step;
    let updatedQty = currentQty;
    if (currentQty <= minQty) {
      updatedQty = minQty;
    }
    formControlObj.setValue(updatedQty);
    this.qtyChangeSubject.next({updatedQty, formControlName});
  }

  incrementQuantity(rowIndex: number, formControlName: string, step: number = 1, maxQty = 1000) {
    this.selectedQtyIndex = rowIndex;
    let formGroupObj = this.multiFormArray.at(rowIndex) as FormGroup;
    let formControlObj = formGroupObj.get(formControlName);
    let currentQty = formControlObj.value + step;
    let updatedQty = currentQty;
    if (currentQty >= maxQty) {
      updatedQty = maxQty;
    }
    formControlObj.setValue(updatedQty);
    this.qtyChangeSubject.next({updatedQty, formControlName});
  }

  onTableFilter(event) {
    this.filteredData.emit({ filteredData: event.filteredValue });
  }

  navigateTo(navigateUrlId) {
    this.navigateClickEvent.emit(navigateUrlId);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.qtyChangeSubject.next(undefined);
    this.qtyChangeSubject.complete();
    this.globalService.clearMessagesOnDestroy();
  }
}
